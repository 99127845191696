<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <p class="title">{{ i18n.reserves[iso] }}</p>
        <!-- <p class="subtitle">Hero subtitle</p> -->
      </div>
    </section>
    <section style="margin-top: 30px">
      <!-- <div class="columns">
        <div class="column is-3">
          <b-field :type="resortFilters ? 'is-info' : ''">
            <b-select
              :disabled="listResortsFilters.length > 0 ? false : true"
              placeholder="Resort"
              v-model="resortFilters"
              @input="findWithFiltersReserves"
              expanded
            >
              <option :value="null">-</option>
              <option
                v-for="(item, idx) in listResortsFilters"
                :value="item"
                :key="idx"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field :type="clientFilters ? 'is-info' : ''">
            <b-select
              :disabled="listClientsFilters.length > 0 ? false : true"
              placeholder="Client"
              v-model="clientFilters"
              @input="findWithFiltersReserves"
              expanded
            >
              <option :value="null">-</option>
              <option
                v-for="(item, idx) in listClientsFilters"
                :value="item.split('|')[0]"
                :key="idx"
              >
                {{ `${item.split("|")[1]} ${item.split("|")[2]}` }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field :type="userFilters ? 'is-info' : ''">
            <b-select
              :disabled="listUsersFilters.length > 0 ? false : true"
              placeholder="User"
              v-model="userFilters"
              @input="findWithFiltersReserves"
              expanded
            >
              <option :value="null">-</option>
              <option
                v-for="(item, idx) in listUsersFilters"
                :value="item"
                :key="idx"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field :type="statusFilters ? 'is-info' : ''">
            <b-select
              :disabled="listStatusFilters.length > 0 ? false : true"
              placeholder="Status"
              v-model="statusFilters"
              @input="findWithFiltersReserves"
              expanded
            >
              <option :value="null">-</option>
              <option
                v-for="(item, idx) in listStatusFilters"
                :value="item"
                :key="idx"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div> -->
      <div class="columns is-multiline">
        <div class="column is-3">
          <b-field label-position="on-border">
            <!-- <b-autocomplete
              v-model="bookingsFilters.Referencia"
              :data="filteredDataArray__Referencia"
              :placeholder="i18n.filtersBookings__reference[iso]"
              icon="magnify"
              clearable
              :open-on-focus="true"
              @select="(option) => (referenciaSelectedSearch = option)"
              @input="findWithFiltersBookings"
            >
              <template #empty>{{ i18n.noResultsFound[iso] }}</template>
            </b-autocomplete> -->
            <b-input
              v-model="bookingsFilters.Referencia"
              :placeholder="i18n.filtersBookings__reference[iso]"
              type="text"
              icon-right="times"
              icon-right-clickable
              expanded
              icon-pack="fas"
              icon="search"
              @input="findWithFiltersBookings"
            >
            </b-input>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field label-position="on-border">
            <b-autocomplete
              v-model="bookingsFilters.Resort"
              :data="filteredDataArray__Resort"
              :placeholder="i18n.filtersBookings__resort[iso]"
              icon-pack="fas"
              icon="search"
              icon-right="times"
              clearable
              :open-on-focus="true"
              @select="(option) => (resortSelectedSearch = option)"
              @input="findWithFiltersBookings"
            >
              <template #empty>{{ i18n.noResultsFound[iso] }}</template>
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field :type="bookingsFilters.Cliente ? 'is-info' : ''">
            <!-- <b-select
              :disabled="listClientsFilters.length > 0 ? false : true"
              :placeholder="i18n.filtersBookings__client[iso]"
              v-model="bookingsFilters.Cliente"
              @input="findWithFiltersBookings"
              expanded
            >
              <option :value="null"></option>
              <option
                v-for="(item, idx) in listClientsFilters"
                :value="item.split('|')[0]"
                :key="idx"
              >
                {{ `${item.split("|")[1]} ${item.split("|")[2]}` }}
              </option>
            </b-select> -->
            <b-input
              v-model="bookingsFilters.Cliente"
              :placeholder="i18n.filtersBookings__client[iso]"
              type="text"
              icon-right="times"
              icon-right-clickable
              expanded
              icon-pack="fas"
              icon="search"
              @input="findWithFiltersBookings"
            >
            </b-input>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field :type="bookingsFilters.Agente ? 'is-info' : ''">
            <b-select
              :disabled="listUsersFilters.length > 0 ? false : true"
              :placeholder="i18n.filtersBookings__agent[iso]"
              v-model="bookingsFilters.Agente"
              @input="findWithFiltersBookings"
              expanded
            >
              <option :value="null"></option>
              <option
                v-for="(item, idx) in listUsersFilters"
                :value="item"
                :key="idx"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field :type="bookingsFilters.Status ? 'is-info' : ''">
            <b-select
              :disabled="listStatusFilters.length > 0 ? false : true"
              :placeholder="i18n.filtersBookings__status[iso]"
              v-model="bookingsFilters.Status"
              @input="findWithFiltersBookings"
              expanded
            >
              <option :value="null"></option>
              <option
                v-for="(item, idx) in listStatusFilters"
                :value="item"
                :key="idx"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="column is-3">
          <b-field :type="bookingsFilters.PaisResort ? 'is-info' : ''">
            <b-select
              :disabled="countriesResorts.length > 0 ? false : true"
              :placeholder="i18n.filtersBookings__country[iso]"
              v-model="bookingsFilters.PaisResort"
              @input="findWithFiltersBookings"
              expanded
            >
              <option :value="null"></option>
              <option
                v-for="(item, idx) in countriesResorts"
                :value="item"
                :key="idx"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="column is-3">
          <b-field :type="bookingsFilters.CiudadResort ? 'is-info' : ''">
            <b-select
              :disabled="citiesResorts.length > 0 ? false : true"
              :placeholder="i18n.filtersBookings__city[iso]"
              v-model="bookingsFilters.CiudadResort"
              @input="findWithFiltersBookings"
              expanded
            >
              <option :value="null"></option>
              <option
                v-for="(item, idx) in citiesResorts"
                :value="item"
                :key="idx"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="column is-3">
          <b-field :type="bookingsFilters.LoginID ? 'is-info' : ''">
            <b-input
              v-model="bookingsFilters.LoginID"
              :placeholder="i18n.filtersBookings__loginId[iso]"
              type="text"
              icon-right="times"
              icon-right-clickable
              expanded
              icon-pack="fas"
              icon="search"
              @input="findWithFiltersBookings"
            >
            </b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label-position="on-border">
            <b-datepicker
              v-model="bookingsFilters.Fecha"
              :placeholder="i18n.filtersBookings__date[iso]"
              :min-date="minDate"
              :max-date="date"
              :locale="iso"
              range
              @input="findWithFiltersBookings"
              icon-right-clickable
              icon-pack="fas"
              icon-right="times"
              @icon-right-click="clearDateAndUpdate('Fecha')"
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field label-position="on-border">
            <b-datepicker
              v-model="bookingsFilters.CheckIn"
              :placeholder="i18n.filtersBookings__checkIn[iso]"
              :min-date="minDate"
              :max-date="maxDate"
              :locale="iso"
              range
              @input="findWithFiltersBookings"
              icon-right-clickable
              icon-pack="fas"
              icon-right="times"
              @icon-right-click="clearDateAndUpdate('CheckIn')"
            >
            </b-datepicker>
          </b-field>
        </div>

        <!-- <div class="column is-2"></div> -->
        <!-- <div class="column is-2">
          <b-button
            icon-left="search"
            icon-pack="fas"
            type="is-info"
            expanded
            >{{ i18n.search[iso] }}</b-button
          >
        </div> -->
        <div class="column is-1">
          <b-button
            icon-left="eraser"
            icon-pack="fas"
            type="is-danger is-light"
            expanded
            @click="clearAll"
          ></b-button>
        </div>
        <div class="column is-2">
          <b-button
            icon-left="file"
            icon-pack="fas"
            type="is-info is-light"
            expanded
            outlined
            :loading="loadingExport"
            @click="exportDataToCsv"
            >{{ i18n.exportToCsv[iso] }}</b-button
          >
        </div>
      </div>

      <!-- <div class="columns is-multiline">
        <div class="column is-3">
          <b-button
            icon-left="file-download"
            icon-pack="fas"
            type="is-info"
            expanded
            >{{ i18n.exportToExcel[iso] }}</b-button
          >
        </div>
      </div> -->

      <b-table
        :data="searchFiltersReserves"
        :paginated="isPaginated"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :pagination-rounded="isPaginationRounded"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :bordered="isBordered"
        :striped="false"
        :narrowed="isNarrowed"
        :hoverable="false"
        :loading="loadingReserves"
        :focusable="isFocusable"
        :mobile-cards="hasMobileCards"
        :row-class="
          (row, index) =>
            row.pay && !row.confirm && !row.cancel ? 'is-error' : ''
        "
      >
        <!-- <b-table-column
          field="ac"
          centered
          label="Hola"
          sortable
          v-slot="props"
          v-show="props.row.pay && !props.row.confirm"
        >
          Hola
        </b-table-column> -->

        <b-table-column
          field="createdAtMs"
          centered
          label="Fecha"
          sortable
          v-slot="props"
        >
          {{ dateToString(props.row.createdAtMs) }}
        </b-table-column>

        <b-table-column
          field="createdBy"
          centered
          label="Usuario"
          sortable
          v-slot="props"
        >
          {{ props.row.createdBy || "dev" }}
        </b-table-column>

        <b-table-column
          field="week.ResortName"
          label="Resort Name"
          sortable
          width="250"
          v-slot="props"
        >
          {{ props.row.week ? props.row.week.ResortName : "n/a" }}
        </b-table-column>

        <b-table-column
          field="numberRef"
          centered
          label="Referencia"
          v-slot="props"
        >
          {{ props.row.numberRef }}
        </b-table-column>

        <b-table-column field="email" centered label="Cliente" v-slot="props">
          {{
            props.row.client
              ? `${
                  props.row.client.name || props.row.client.TravelerFirstName
                } ${
                  props.row.client.lastname || props.row.client.TravelerLastName
                }`
              : "n/a"
          }}
          <!-- checkPermission('modifyTravelerData')" -->
          <b-tooltip
            type="is-info is-light"
            v-if="
              props.row.client && $checkPermission('modify-traveler-bookings')
            "
          >
            <b-tag
              type="is-info is-light"
              style="cursor: pointer"
              @click="
                openAddTravel(props.row.client || null, props.row.numberRef)
              "
              ><b-icon icon="user-edit" pack="fas" size="is-small"> </b-icon
            ></b-tag>
            <template v-slot:content> <b>Editar Viajero</b></template>
          </b-tooltip>
          <b-tooltip
            type="is-warning is-light"
            v-else-if="
              !props.row.client && $checkPermission('add-traveler-bookings')
            "
          >
            <b-tag
              type="is-warning is-light"
              style="cursor: pointer"
              @click="
                openAddTravel(props.row.client || null, props.row.numberRef)
              "
              ><b-icon icon="user-plus" pack="fas" size="is-small"> </b-icon
            ></b-tag>
            <template v-slot:content>
              <b>{{ i18n.addTraveler[iso] }}</b></template
            >
          </b-tooltip>
        </b-table-column>

        <b-table-column
          field="week.CheckInDate"
          centered
          label="CheckIn"
          sortable
          v-slot="props"
        >
          {{
            props.row.week ? dateToString(props.row.week.CheckInDate) : "n/a"
          }}
        </b-table-column>

        <b-table-column
          field="week.CheckOutDate"
          label="CheckOut"
          centered
          sortable
          v-slot="props"
        >
          {{
            props.row.week ? dateToString(props.row.week.CheckOutDate) : "n/a"
          }}
        </b-table-column>

        <b-table-column field="price" label="Cost" sortable v-slot="props">
          $ {{ (props.row.week && props.row.week.Price) || "" }}
        </b-table-column>

        <b-table-column field="price" label="Price" sortable v-slot="props">
          $ {{ props.row.price }}
        </b-table-column>

        <b-table-column field="pay" label="Pago" centered v-slot="props">
          <b-icon
            v-if="props.row.pay"
            pack="fas"
            icon="check"
            size="is-small"
            type="is-success"
          >
          </b-icon>
          <b-icon
            v-else
            pack="fas"
            icon="times"
            size="is-small"
            type="is-danger"
          >
          </b-icon>
          <!-- <b-tag v-if="props.row.pay" type="is-success">SI</b-tag>
          <b-tag v-else type="is-danger">NO</b-tag> -->
        </b-table-column>

        <b-table-column field="status" label="Status" centered v-slot="props">
          <!-- <p
            :style="
              props.row.status === 'RR'
                ? 'color: #48c78e; font-weight: 600;'
                : props.row.status === 'NW'
                ? 'color: #ffe08a; font-weight: 600;'
                : 'color: #f14668; font-weight: 600;'
            "
          >
            {{ props.row.status || "N/A" }}
          </p> -->
          <b-tag
            :type="
              props.row.status === 'RR'
                ? 'is-success'
                : props.row.status === 'NW'
                ? 'is-warning'
                : 'is-danger'
            "
            >{{ props.row.status || "N/A" }}</b-tag
          >
        </b-table-column>

        <!-- <b-table-column
          field="confirm"
          label="Confirmacion"
          centered
          v-slot="props"
        >
          <b-tag v-if="props.row.confirm" type="is-success">SI</b-tag>
          <b-tag v-else type="is-danger">NO</b-tag>
        </b-table-column>

        <b-table-column
          field="cancel"
          label="Cancelacion"
          centered
          v-slot="props"
        >
          <b-tag v-if="props.row.cancel" type="is-success">SI</b-tag>
          <b-tag v-else type="is-danger">NO</b-tag>
        </b-table-column> -->

        <b-table-column
          label="Actions"
          v-slot="props"
          centered
          width="250"
          v-if="
            $checkTotalPermissionsAvailable([
              'send-payment-bookings',
              'get-payments-bookings',
              'send-confirmation-bookings',
              'hold-force-bookings',
              'get-logs-bookings',
              'hold-release-bookings',
            ])
          "
        >
          <b-tooltip
            type="is-success is-light"
            v-if="
              !props.row.paymentId && $checkPermission('send-payment-bookings')
            "
            :always="isShowOptionsPayments"
            multilined
            :active="activeShowOptionsPayments === props.row.numberRef"
          >
            <!-- @click="sendPayment(props.row.numberRef, props.row.email)" -->
            <b-button
              icon-left="credit-card"
              icon-pack="fas"
              size="is-small"
              type="is-success"
              style="margin-right: 10px"
              :loading="loadingSendPayment === props.row.numberRef"
              @click="showOptionsPayments(props.row.numberRef)"
              :disabled="
                props.row.cancel
                  ? true
                  : !props.row.email
                  ? true
                  : props.row.pay
                  ? true
                  : false
              "
            ></b-button>
            <template v-slot:content>
              <b-tooltip label="Enviar Enlace">
                <b-button
                  icon-left="paper-plane"
                  icon-pack="fas"
                  size="is-small"
                  type="is-success"
                  @click="sendPayment(props.row.numberRef, props.row.email)"
                  style="margin-right: 10px"
                ></b-button>
              </b-tooltip>

              <b-tooltip label="Copiar Enlace">
                <b-button
                  icon-left="copy"
                  icon-pack="fas"
                  size="is-small"
                  type="is-info"
                  @click="copyLinkPayment(props.row.numberRef)"
                  style="margin-right: 10px"
                ></b-button>
              </b-tooltip>

              <b-tooltip label="Agregar pago externo">
                <b-button
                  icon-left="pen"
                  icon-pack="fas"
                  size="is-small"
                  type="is-dark"
                  @click="addPaymentExternal(props.row.numberRef)"
                ></b-button>
              </b-tooltip>
            </template>
          </b-tooltip>
          <b-tooltip
            type="is-success is-light"
            v-if="
              props.row.paymentId && $checkPermission('get-payments-bookings')
            "
          >
            <b-button
              icon-left="dollar-sign"
              icon-pack="fas"
              size="is-small"
              type="is-success is-light"
              style="margin-right: 10px"
              @click="showPayment(props.row.paymentId)"
            ></b-button>
            <template v-slot:content> <b>Visualizar Pago</b></template>
          </b-tooltip>

          <b-tooltip
            type="is-warning"
            v-if="
              props.row.pay &&
              !props.row.confirm &&
              !props.row.cancel &&
              $checkPermission('hold-force-bookings')
            "
            multilined
          >
            <b-button
              icon-left="exclamation"
              icon-pack="fas"
              size="is-small"
              :type="timeErrorConfirm ? 'is-dark' : 'is-light'"
              style="margin-right: 10px"
              :loading="loadingConfirmHold === props.row.numberRef"
              @click="confirmHold(props.row.numberRef)"
            ></b-button>
            <template v-slot:content>
              <b>Confirmar Reserva</b> <br />
              Hubo un error en la confirmacion automatica y se requiere la
              accion manual.
            </template>
          </b-tooltip>

          <b-tooltip
            type="is-warning is-light"
            v-else-if="
              props.row.pay && $checkPermission('send-confirmation-bookings')
            "
            :always="isShowOptionsConfirmation"
            multilined
            :active="activeShowOptionsConfirmation === props.row.numberRef"
          >
            <b-button
              icon-left="inbox"
              icon-pack="fas"
              size="is-small"
              type="is-warning"
              style="margin-right: 10px"
              :loading="loadingReSendConfirmation === props.row.numberRef"
              @click="showOptionsConfirmation(props.row.numberRef)"
              :disabled="
                props.row.cancel
                  ? true
                  : !props.row.email
                  ? true
                  : !props.row.confirm
                  ? true
                  : false
              "
            ></b-button>
            <!-- <template v-slot:content> <b>Reenviar Confirmacion</b></template> -->
            <template v-slot:content>
              <b-tooltip label="Enviar Confirmacion">
                <b-button
                  icon-left="envelope"
                  icon-pack="fas"
                  size="is-small"
                  type="is-success"
                  @click="
                    showModalSendConfirmation(
                      props.row.numberRef,
                      props.row.email
                    )
                  "
                  style="margin-right: 10px"
                ></b-button>
              </b-tooltip>

              <b-tooltip label="Abrir Confirmacion">
                <b-button
                  icon-left="file"
                  icon-pack="fas"
                  size="is-small"
                  type="is-info"
                  @click="showConfirmation(props.row.numberRef)"
                  style="margin-right: 10px"
                ></b-button>
              </b-tooltip>

              <b-tooltip label="Descargar Confirmacion">
                <b-button
                  icon-left="download"
                  icon-pack="fas"
                  size="is-small"
                  type="is-dark"
                  @click="downloadPdfConfirmation(props.row.numberRef)"
                ></b-button>
              </b-tooltip>
            </template>
          </b-tooltip>

          <!-- v-show="checkPermission('releaseHold')" -->
          <b-tooltip
            type="is-danger"
            v-if="$checkPermission('hold-release-bookings')"
          >
            <b-button
              icon-left="calendar-times"
              icon-pack="fas"
              size="is-small"
              type="is-danger"
              style="margin-right: 10px"
              :loading="loadingReleaseHold === props.row.numberRef"
              @click="releaseHold(props.row.numberRef)"
              :disabled="
                props.row.cancel ? true : props.row.confirm ? true : false
              "
            ></b-button>
            <template v-slot:content> <b>Liberar Reserva</b></template>
          </b-tooltip>
          <!-- <b-tooltip type="is-dark" v-else>
            <b-button
              icon-left="lock"
              icon-pack="fas"
              size="is-small"
              type="is-dark"
              style="margin-right: 10px"
              disabled
            ></b-button>
            <template v-slot:content>
              <b>{{ i18n.blockedByPermissions[iso] }}</b></template
            >
          </b-tooltip> -->
          <b-tooltip
            type="is-info"
            v-if="$checkPermission('get-logs-bookings')"
          >
            <b-button
              icon-left="eye"
              icon-pack="fas"
              size="is-small"
              type="is-info"
              style="margin-right: 10px"
              @click="openViewLogs(props.row.numberRef)"
            ></b-button>
            <template v-slot:content> <b>Ver logs</b></template>
          </b-tooltip>
          <b-tooltip type="is-primary">
            <b-button
              icon-left="file"
              icon-pack="fas"
              size="is-small"
              type="is-primary"
              @click="openDetails(props.row.week)"
            ></b-button>
            <template v-slot:content>
              <b>{{ i18n.details[iso] }}</b></template
            >
          </b-tooltip>
        </b-table-column>
        <template #footer>
          <div class="columns">
            <div class="column is-11">
              <!-- <p>Soluionar problemas</p> -->
              <!-- <b-message type="is-warning" has-icon style="text-align: center">
                Se encontro 4 reservas pendiente por confirmar. <br />
                <a @click="openConfirmPendingHold">Confirmar pendientes</a>
              </b-message> -->
            </div>
            <!-- <div class="column is-2">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <b-button
                  style="margin-top: 10%"
                  icon-left="file-download"
                  icon-pack="fas"
                  size="is-small"
                  type="is-info"
                  >Export Excel</b-button
                >
              </div>
            </div> -->
            <div class="column is-1">
              <b-field :label="i18n.quantity[iso]">
                <b-select v-model="perPage" @input="savePerPage" expanded>
                  <option :value="5">5</option>
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <!-- <div class="has-text-right">Footer</div> -->
        </template>
      </b-table>
      <b-modal
        v-model="isComponentModalActive"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-label="Hold"
        aria-modal
      >
        <template #default="props">
          <modal-hold
            mode="add-travel"
            :travel="travel"
            :numberRef="numberRefForAddTravel"
            @close="props.close"
            @refresh="listReserves"
          ></modal-hold>
        </template>
      </b-modal>
      <b-modal
        v-model="isComponentModalActiveLogs"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-label="Logs"
        aria-modal
      >
        <template #default="props">
          <modal-log
            :numberRef="numberRefForViewLogs"
            @close="props.close"
          ></modal-log>
        </template>
      </b-modal>
      <b-modal
        v-model="isComponentModalPayExternal"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-label="PayExternal"
        aria-modal
      >
        <template #default="props">
          <modal-pay-external
            :numberRef="numberRefForPayExternal"
            @close="props.close"
            @refresh="listReserves"
          ></modal-pay-external>
        </template>
      </b-modal>
      <b-modal
        v-model="isComponentModalSendConfirmation"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-label="SendConfirmation"
        aria-modal
      >
        <template #default="props">
          <modal-send-confirmation
            :numberRef="numberRefForSendConfirmation"
            :email="emailForSendConfirmation"
            @close="props.close"
          ></modal-send-confirmation>
        </template>
      </b-modal>
    </section>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import ModalHold from "./ModalHold.vue";
import ModalResort from "./ModalViewResort.vue";
import ModalPayExternal from "./ModalPayExternal.vue";
import ModalLog from "./ModalLog.vue";
import ModalSendConfirmation from "./ModalSendConfirmation.vue";
import moment from "moment";
import i18n, { exportToCsv } from "../utils/i18n";

const today = new Date();

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,

      //
      reserves: [],

      //Modal
      isComponentModalActive: false,
      travel: null,
      numberRefForAddTravel: null,
      isComponentModalActiveLogs: false,
      numberRefForViewLogs: null,
      isComponentModalPayExternal: false,
      numberRefForPayExternal: null,
      isComponentModalSendConfirmation: false,
      numberRefForSendConfirmation: null,
      emailForSendConfirmation: null,

      //Loadings
      loadingSendPayment: null,
      loadingReSendConfirmation: null,
      loadingReleaseHold: null,
      loadingConfirmHold: null,
      loadingReserves: false,

      //Error
      timeErrorConfirm: true,

      //i18n
      i18n,
      iso: null,

      //Payment
      isShowOptionsPayments: false,
      activeShowOptionsPayments: null,
      isShowOptionsConfirmation: false,
      activeShowOptionsConfirmation: null,

      //Permissions
      permissions: "",

      //Filters
      loadingExport: false,
      bookingsFilters: {
        Fecha: [],
        CheckIn: [],
        CheckOut: [],
        Referencia: null,
        Resort: null,
        Cliente: null,
        Agente: null,
        Status: null,
        LoginID: null,
        PaisResort: null,
        CiudadResort: null,
      },
      searchReferencias: [],
      referenciaSelectedSearch: null,
      searchResort: [],
      resortSelectedSearch: null,
      listClientsFilters: [],
      clientFilters: null,

      listResortsFilters: [],
      resortFilters: null,

      listUsersFilters: [],
      userFilters: null,
      listStatusFilters: [],
      statusFilters: null,
      searchFiltersReserves: [],

      countriesResorts: [],
      citiesResorts: [],

      /**
       * Dates
       */
      date: new Date(),
      minDate: new Date(
        today.getFullYear() - 80,
        today.getMonth(),
        today.getDate()
      ),
      maxDate: new Date(
        today.getFullYear() + 2,
        today.getMonth(),
        today.getDate()
      ),
    };
  },
  created() {
    this.listReserves();
    setInterval(this.intervalErrorConfirm, 500);

    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    //PerPage
    const perPageLocalStorage = localStorage.getItem("perPageReserves");
    if (!perPageLocalStorage) this.savePerPage();
    else this.perPage = perPageLocalStorage;

    //Permissions
    this.permissions = localStorage.getItem("role");
  },
  mounted() {},
  components: {
    ModalHold,
    ModalResort,
    ModalLog,
    ModalPayExternal,
    ModalSendConfirmation,
  },
  computed: {
    filteredDataArray__Referencia() {
      if (
        this.bookingsFilters.Referencia &&
        this.bookingsFilters.Referencia !== ""
      )
        return this.searchReferencias.filter((option) => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.bookingsFilters.Referencia.toLowerCase()) >= 0
          );
        });
      else return this.searchReferencias;
    },
    filteredDataArray__Resort() {
      if (this.bookingsFilters.Resort && this.bookingsFilters.Resort !== "")
        return this.searchResort.filter((option) => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.bookingsFilters.Resort.toLowerCase()) >= 0
          );
        });
      else return this.searchResort;
    },
  },
  methods: {
    listReserves() {
      this.loadingReserves = true;
      axios({
        method: "get",
        url: `${API_URL}/reserve/list`,
      })
        .then(({ data }) => {
          console.log(data);
          this.reserves = data.reserves;
          this.searchFiltersReserves = this.reserves;
          this.loadingReserves = false;

          //Filters
          this.searchResort = [
            ...new Set(
              this.reserves.filter((r) => r.week).map((r) => r.week.ResortName)
            ),
          ].sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));

          this.listClientsFilters = [
            ...new Set(
              this.reserves
                .filter((r) => r.email && r.client)
                .sort((a, b) =>
                  a.client.name > b.client.name
                    ? 1
                    : b.client.name > a.client.name
                    ? -1
                    : 0
                )
                .map((r) => {
                  return `${r.email}|${
                    r.client.name || r.client.TravelerFirstName
                  }|${r.client.lastname || r.client.TravelerLastName}`;
                  // return {
                  //   email: r.email,
                  //   fullname: `${r.client.name} ${r.client.lastname}`,
                  // };
                })
            ),
          ];

          this.listUsersFilters = [
            ...new Set(
              this.reserves.filter((r) => r.createdBy).map((r) => r.createdBy)
            ),
          ].sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));

          this.listStatusFilters = [
            ...new Set(
              this.reserves.filter((r) => r.status).map((r) => r.status)
            ),
          ];

          this.searchReferencias = this.reserves
            .filter((r) => r.numberRef)
            .map((r) => r.numberRef);

          this.countriesResorts = [
            ...new Set(
              this.reserves
                .filter((r) => r?.week?.ResortCountry)
                .map((r) => r?.week?.ResortCountry)
            ),
          ].sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));

          this.citiesResorts = [
            ...new Set(
              this.reserves
                .filter((r) => r?.week?.ResortCity)
                .map((r) => r?.week?.ResortCity)
            ),
          ].sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));

          // this.reserves = this.reserves.map((r) => {
          //   let result = { ...r };
          //   result.week.CheckInDate = new Date(r.week.CheckInDate);
          //   return result;
          // });
          // console.log(this.reserves);
        })
        .catch((err) => {
          this.loadingReserves = false;
          console.log(err);
          this.$buefy.toast.open({
            message: "Error al consultar datos",
            type: "is-danger",
          });
        });
    },
    sendPayment(numberRef, email) {
      this.$buefy.dialog.confirm({
        title: "Emitir Pago",
        message: `¿Esta seguro de emitir enlace de pago?`,
        cancelText: "No",
        confirmText: "Si, enviar enlace",
        type: "is-success",
        onConfirm: () => {
          this.loadingSendPayment = numberRef;
          axios({
            method: "get",
            url: `${API_URL}/week/hold-pay/redirect?numberRef=${numberRef}&type=send`,
          })
            .then(({ data }) => {
              console.log(data);
              this.loadingSendPayment = null;
              this.$buefy.toast.open({
                message: `El Pago Nro. ${numberRef} fue emitido al correo ${email}`,
                type: "is-success",
                duration: 5000,
              });
            })
            .catch((err) => {
              this.loadingSendPayment = null;
              console.log(err);
              this.$buefy.toast.open({
                message: "Error al consultar datos",
                type: "is-danger",
              });
            });
          this.isShowOptionsPayments = false;
          this.activeShowOptionsPayments = null;
        },
      });
    },
    dateToString(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    openAddTravel(client, numberRef) {
      this.travel = client;
      this.numberRefForAddTravel = numberRef;
      this.isComponentModalActive = true;
    },
    reSendConfirmationEmail(numberRef) {
      this.loadingReSendConfirmation = numberRef;
      axios({
        method: "get",
        url: `${API_URL}/week/send-email/confirm-hold?numberRef=${numberRef}`,
      })
        .then(({ data }) => {
          console.log(data);
          this.loadingReSendConfirmation = null;
          this.activeShowOptionsConfirmation = null;
          this.isShowOptionsConfirmation = false;
          this.$buefy.toast.open({
            message: "Correo de confirmacion reenviado con exito",
            type: "is-success",
          });
        })
        .catch((err) => {
          console.log(err);
          this.loadingReSendConfirmation = null;
          this.$buefy.toast.open({
            message: "Error al reenviar confirmacion",
            type: "is-danger",
          });
        });
    },
    releaseHold(numberRef) {
      this.loadingReleaseHold = numberRef;
      axios({
        method: "delete",
        url: `${API_URL}/week/hold-release?numberRef=${numberRef}`,
      })
        .then(({ data }) => {
          console.log(data);
          this.loadingReleaseHold = null;
          this.listReserves();
          this.$buefy.toast.open({
            message: "Reserva liberada con exito",
            type: "is-success",
          });
        })
        .catch((err) => {
          console.log(err);
          this.loadingReleaseHold = null;
          this.$buefy.toast.open({
            message: "Error al liberar la reserva",
            type: "is-danger",
          });
        });
    },
    openViewLogs(numberRef) {
      this.numberRefForViewLogs = numberRef;
      this.isComponentModalActiveLogs = true;
    },
    showPayment(payment) {
      this.$buefy.dialog.alert({
        title: "Detalles del Pago",
        message: `<ul>
        <li><strong>StripeID</strong>: ${payment.stripeId || "n/a"}</li>
        <li><strong>Monto</strong>: ${payment.amount}&nbsp;</li>
        <li><strong>Fecha</strong>: ${this.dateToString(
          payment.createdAtMs
        )}</li>
        <li><strong>Proveedor</strong>: ${payment.type}</li>
        </ul>`,
        confirmText: "OK",
        type: "is-success is-light",
      });
    },
    // openConfirmPendingHold() {
    //   this.$buefy.dialog.alert({
    //     // message: `What's your age?`,
    //     message: `<b-select placeholder="Select a name">

    //         </b-select>`,
    //     trapFocus: true,
    //     onConfirm: (value) => this.$buefy.toast.open(`Your age is: ${value}`),
    //   });
    // },
    intervalErrorConfirm() {
      // console.log('this.timeErrorConfirm', this.timeErrorConfirm)
      // if (this.timeErrorConfirm === 4) this.timeErrorConfirm = 1;
      // else this.timeErrorConfirm += 1;
      this.timeErrorConfirm = !this.timeErrorConfirm;
    },
    confirmHold(numberRef) {
      this.loadingConfirmHold = numberRef;
      axios({
        method: "put",
        url: `${API_URL}/week/confirm-hold/${numberRef}`,
      })
        .then(({ data }) => {
          console.log(data);
          this.loadingConfirmHold = null;
          this.listReserves();
          this.$buefy.toast.open({
            message: "Reserva confirmada con exito",
            type: "is-success",
          });
        })
        .catch((err) => {
          console.log(err);
          this.loadingConfirmHold = null;
          this.$buefy.toast.open({
            message: "Error al confirmar la reserva",
            type: "is-danger",
          });
        });
    },

    showOptionsPayments(numberRef) {
      this.activeShowOptionsConfirmation = null;
      this.isShowOptionsConfirmation = false;

      if (this.activeShowOptionsPayments === numberRef)
        this.activeShowOptionsPayments = null;
      else this.activeShowOptionsPayments = numberRef;
      this.isShowOptionsPayments = !this.isShowOptionsPayments;
    },
    showOptionsConfirmation(numberRef) {
      this.activeShowOptionsPayments = null;
      this.isShowOptionsPayments = false;

      if (this.activeShowOptionsConfirmation === numberRef)
        this.activeShowOptionsConfirmation = null;
      else this.activeShowOptionsConfirmation = numberRef;
      this.isShowOptionsConfirmation = !this.isShowOptionsConfirmation;
    },
    copyLinkPayment(numberRef) {
      navigator.clipboard.writeText(
        `${API_URL}/week/hold-pay/redirect?numberRef=${numberRef}&type=nosend`
      );
      this.$buefy.toast.open({
        message: "Enlace copiado",
        type: "is-info",
      });
      this.isShowOptionsPayments = false;
      this.activeShowOptionsPayments = null;
    },
    addPaymentExternal(numberRef) {
      this.numberRefForPayExternal = numberRef;
      this.isComponentModalPayExternal = true;
    },
    savePerPage() {
      localStorage.setItem("perPageReserves", this.perPage);
    },
    async showConfirmation(numberRef) {
      this.loadingReSendConfirmation = numberRef;
      await window.open(
        `${API_URL}/file/show?numberRef=${numberRef}&type=confirmation&generateFile=yes&ext=html`,
        "_blank"
      );
      this.loadingReSendConfirmation = null;
    },
    async downloadPdfConfirmation(numberRef) {
      this.loadingReSendConfirmation = numberRef;
      await window.open(
        `${API_URL}/file/download?numberRef=${numberRef}&type=confirmation&ext=pdf`,
        "_blank"
      );
      this.loadingReSendConfirmation = null;
    },

    showModalSendConfirmation(numberRef, email) {
      this.numberRefForSendConfirmation = numberRef;
      this.emailForSendConfirmation = email;
      this.isComponentModalSendConfirmation = true;
    },
    checkPermission(permission) {
      const rgx = new RegExp(permission, "ig");
      return rgx.test(this.permissions);
    },
    findWithFiltersReserves() {
      let resultsReserves = this.reserves;

      if (this.resortFilters)
        resultsReserves = resultsReserves.filter(
          (r) => r.week && r.week.ResortName === this.resortFilters
        );
      if (this.clientFilters)
        resultsReserves = resultsReserves.filter(
          (r) => r.email && r.client && r.email === this.clientFilters
        );
      if (this.userFilters)
        resultsReserves = resultsReserves.filter(
          (r) => r.createdBy && r.createdBy === this.userFilters
        );
      if (this.statusFilters)
        resultsReserves = resultsReserves.filter(
          (r) => r.status && r.status === this.statusFilters
        );

      this.searchFiltersReserves = resultsReserves;
    },
    findWithFiltersBookings() {
      let resultsBookings = this.reserves;

      if (
        this.bookingsFilters.Referencia &&
        this.bookingsFilters.Referencia.trim() !== ""
      ) {
        resultsBookings = resultsBookings.filter((w) => {
          const rgx = new RegExp(this.bookingsFilters.Referencia, "gi");
          if (rgx.test(w.numberRef)) return w;
        });
      }

      if (
        this.resortSelectedSearch &&
        this.resortSelectedSearch.trim() !== ""
      ) {
        resultsBookings = resultsBookings.filter((w) => {
          const rgx = new RegExp(this.bookingsFilters.Resort, "gi");
          if (rgx.test(w?.week?.ResortName)) return w;
        });
      }

      // if (this.bookingsFilters.Cliente)
      //   resultsBookings = resultsBookings.filter(
      //     (r) => r.email && r.client && r.email === this.bookingsFilters.Cliente
      //   );

      if (
        this.bookingsFilters.Cliente &&
        this.bookingsFilters.Cliente.trim() !== ""
      ) {
        resultsBookings = resultsBookings.filter((w) => {
          const rgx = new RegExp(this.bookingsFilters.Cliente, "gi");
          if (rgx.test(w?.client?.name) || rgx.test(w?.client?.lastname))
            return w;
        });
      }

      if (
        this.bookingsFilters.LoginID &&
        this.bookingsFilters.LoginID.trim() !== ""
      ) {
        resultsBookings = resultsBookings.filter((w) => {
          const rgx = new RegExp(this.bookingsFilters.LoginID, "gi");
          if (rgx.test(w?.client?.loginId)) return w;
        });
      }

      if (this.bookingsFilters.Agente)
        resultsBookings = resultsBookings.filter(
          (r) => r.createdBy && r.createdBy === this.bookingsFilters.Agente
        );
      if (this.bookingsFilters.Status)
        resultsBookings = resultsBookings.filter(
          (r) => r.status && r.status === this.bookingsFilters.Status
        );

      if (this.bookingsFilters.Fecha && this.bookingsFilters.Fecha.length > 0) {
        const min = moment(this.bookingsFilters.Fecha[0])
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .valueOf();
        const max = moment(this.bookingsFilters.Fecha[1])
          .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
          .valueOf();

        resultsBookings = resultsBookings.filter(
          (r) => r.createdAtMs >= min && r.createdAtMs <= max
        );
      }

      if (
        this.bookingsFilters.CheckIn &&
        this.bookingsFilters.CheckIn.length > 0
      ) {
        const min = moment(this.bookingsFilters.CheckIn[0])
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .valueOf();
        const max = moment(this.bookingsFilters.CheckIn[1])
          .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
          .valueOf();

        resultsBookings = resultsBookings.filter((r) => {
          if (!r?.week?.CheckInDate) return null;
          let tmp__checkIn = moment(r?.week?.CheckInDate);
          return tmp__checkIn >= min && tmp__checkIn <= max;
        });
      }

      if (this.bookingsFilters.PaisResort)
        resultsBookings = resultsBookings.filter(
          (r) =>
            r?.week?.ResortCountry &&
            r?.week?.ResortCountry === this.bookingsFilters.PaisResort
        );

      if (this.bookingsFilters.CiudadResort)
        resultsBookings = resultsBookings.filter(
          (r) =>
            r?.week?.ResortCity &&
            r?.week?.ResortCity === this.bookingsFilters.CiudadResort
        );

      this.searchFiltersReserves = resultsBookings;

      // if (this.referenciaSelectedSearch) {
      //   if (
      //     (this.referenciaSelectedSearch.trim() === "" ||
      //       !this.referenciaSelectedSearch) &&
      //     !this.size &&
      //     !this.kitchen &&
      //     !this.occupancy &&
      //     !this.weekType
      //   ) {
      //     this.isFilters = false;
      //   } else {
      //     this.isFilters = true;
      //     this.searchWeeks = resultWeeks;
      //   }
      // } else {
      //   if (!this.size && !this.kitchen && !this.occupancy && !this.weekType) {
      //     this.isFilters = false;
      //   } else {
      //     this.isFilters = true;
      //     this.searchWeeks = resultWeeks;
      //   }
      // }
    },
    clearDateAndUpdate(item) {
      this.bookingsFilters[item] = [];
      this.findWithFiltersBookings();
    },
    clearAll() {
      this.bookingsFilters.Referencia = null;
      this.referenciaSelectedSearch = null;
      this.bookingsFilters.Resort = null;
      this.resortSelectedSearch = null;
      this.bookingsFilters.Cliente = null;
      this.bookingsFilters.Agente = null;
      this.bookingsFilters.Status = null;
      this.bookingsFilters.Fecha = [];
      this.bookingsFilters.CheckIn = [];
      this.findWithFiltersBookings();
    },
    exportDataToCsv() {
      const items = this.searchFiltersReserves.map((b) => {
        return {
          Fecha: moment(b.createdAtMs).format("ll"),
          Usuario: b?.createdBy || "",
          ResortName: b?.week?.ResortName || "",
          Referencia: b?.numberRef || "",
          Cliente: b?.client?.name + b?.client?.lastname || "",
          CheckIn: b?.week?.CheckInDate || "",
          CheckOut: b?.week?.CheckOutDate || "",
          Costo: b?.week?.Cost || "",
          Precio: b?.price || "",
          EstadoPago: (b?.pay && "PAGADO") || "NO PAGADO",
          EstadoReserva: b?.status || "",
        };
      });
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]);
      const csv = [
        header.join(","),
        ...items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");

      // console.log(csv);

      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", "bookings.csv");
      a.click();
    },
    async openDetails(details) {
      await localStorage.setItem("reserveDetails", JSON.stringify(details));
      await window.open("/reserve-details", "_blank");
    },
  },
};
</script>

<style>
tr.is-error {
  background: #ffe08a71;
  /* cursor: pointer; */
}
/* tr.is-error:hover {
  background: #f14668c5;
  cursor: pointer;
} */
</style>
